import React from 'react';
import { Container, Jumbotron, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardDeck, Media } from 'reactstrap';
import Accordion from "../components/accordian-component"
import JumbotronBackground from "../assets/img/jumbotron-5-img.jpg"
import FadingFooter from "../components/fading-footer.component"

import "../assets/css/servicePageC.css"

import img1 from "../assets/img/recovery-img-1.png"
import img2 from "../assets/img/recovery-img-2.png"
import img3 from "../assets/img/recovery-img-3.png"
import img4 from "../assets/img/recovery-img-4.png"

class ServicesPageC extends React.Component {
    render() {
        return (
            <div>
                <Jumbotron className="center" fluid style={{ backgroundImage: `url(${JumbotronBackground})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                    <div>
                        <h3 className="display-4 text-light text-center" color="light">Disaster Recovery</h3>
                        <hr color="white" />
                        <p className="lead text-light text-center" color="light">Ensure your business is protected</p>
                    </div>
                </Jumbotron>

                <section className="section mt-2">
                    <Container>
                        <Row>
                            <Col className="p-2" sm="6">
                                <Media src={img1}></Media>
                            </Col>
                            <Col className="p-2" sm="6">
                                <h3>Reliable Protection against Business Disasters</h3>
                                <div className="center" >
                                    <p>Business disaster can strike at any time. Tech Valley Networking’s managed IT services’ clients rest easy knowing that we are protecting them against all manners of disaster – natural disasters, theft, technological malfunctions, etc. We offer detailed disaster recovery plans for our clients, ensuring a quick and organized plan of attack in the case of a disaster. A detailed disaster recovery and backup plan can save you thousands to hundreds of thousands of dollars in the event of a disaster. Tech Valley Networking has comprehensive cloud and local backup plans designed to get you up and running and soon as possible while simultaneously protecting your business against business disasters of any nature.</p>
                                </div>
                                <p className="font-weight-bold font-italic">HIPAA COMPLIANT | PCI COMPLIANT | NIST | FIPS 140-2 | GDPR Availability</p>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section mt-2">
                    <Container>
                        <Row>
                            <Col className="p-2" sm="6">
                                <h3>Layers of Protection</h3>
                                <Accordion>
                                    <Accordion.Item>
                                        <Accordion.Header>Comprehensive Backups</Accordion.Header>
                                        <Accordion.Body>Ensure your infrastructure is protected with cloud and local backups. Rest easy knowing that all your important data is recoverable in the case of a disaster.</Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item>
                                        <Accordion.Header>Disaster Recovery Plan</Accordion.Header>
                                        <Accordion.Body>A step by step disaster recovery plan is vital for your business’ preparation for a disaster situation. This plan ensures you can get your business up and running as soon as possible to mitigate the impact on your clients.</Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item>
                                        <Accordion.Header>Infrastructure Monitoring</Accordion.Header>
                                        <Accordion.Body>Tech Valley Networking’s fully managed IT services includes full infrastructure monitoring for our clients. Proactive infrastructure monitoring allows us to prevent small issues from becoming large disasters.</Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                            <Col className="p-2" sm="6">
                                <h3>Disaster Protection</h3>
                                <div className="align-items-center">
                                    <div className="progress mb-2" style={{ height: 40 }}>
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                            Cloud/Local Backups 100%
                                        </div>
                                    </div>
                                    <div className="progress mb-2" style={{ height: 40 }}>
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                            Disaster Recovery Plan 100%
                                        </div>
                                    </div>
                                    <div className="progress mb-2" style={{ height: 40 }}>
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                            Infrastructure Monitoring 100%
                                        </div>
                                    </div>
                                    <div className="progress mb-2" style={{ height: 40 }}>
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                            Responsive Monitoring 100%
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <hr />
                <section className="section mt-2">
                    <Container>
                        <h3>Business Backup Solutions</h3>

                        <CardDeck>
                            <Card>
                                <CardImg className="zoom" top src={img2} alt="Card image cap" height="340" width="auto" />
                                <hr />
                                <CardBody>
                                    <CardTitle className="card-title">Hybrid Solutions</CardTitle>
                                    <CardText>Hybrid solutions combine the peace of mind of offsite backups with the speedy restore functionality of local backups</CardText>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardImg className="zoom" top src={img3} alt="Card image cap" height="340" width="auto" />
                                <hr />
                                <CardBody>
                                    <CardTitle className="card-title">Cloud Backups</CardTitle>
                                    <CardText>Enterprise level encryption (256bit AES) and security ensure your data is protected with offsite backups. HIPAA Compliant for Peace of Mind</CardText>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardImg className="zoom" top src={img4} alt="Card image cap" height="340" width="auto" />
                                <hr />
                                <CardBody>
                                    <CardTitle className="card-title">Local Backups</CardTitle>
                                    <CardText>Local backups provide the quickest recovery times as your backups are stored on your network and easily accessible</CardText>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Container>
                </section>
                <section className="section mt-5">
                    <FadingFooter />
                </section>
            </div>
        );
    }
}

export default ServicesPageC;