import React from 'react';
import RSSParser from 'rss-parser'
import { Card, Button, CardText, CardBody, CardHeader, Spinner} from 'reactstrap'
import "../assets/css/FeedPage.css"

// Note: some RSS feeds can't be loaded in the browser due to CORS security.
// To get around this, you can use a proxy.
const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"

function getTimeDifference(dateTime) {
    var now = new Date();
    var diff = (dateTime.getTime() - now.getTime()) / 1000;
    diff /= (60 * 60);
    console.log(diff);
    if (Math.abs(diff) < 1) {
        diff = diff * 60;
        diff = Math.abs(Math.round(diff));
        if (diff === 1){
            return String(diff) + " minute ago";
        }
        return String(diff) + " minutes ago";
    }
    diff = Math.abs(Math.round(diff));
    if (diff === 1){
        return String(diff) + " hour ago";
    }
    return String(diff) + " hours ago";
}

class NewsFeed extends React.Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            feedURL: props.src,
            currentFeed: null
        };
        this.openLink = this.openLink.bind(this);
    }

    openLink = url => {
        window.location.href = url;
    }

    componentDidMount = () => {
        let parser = new RSSParser();
        parser.parseURL(CORS_PROXY + this.state.feedURL).then((feed) => {
            console.log(feed.items);
            this.setState({ currentFeed: feed });
        });
    }

    formatFeedCards = () => {
        return this.state.currentFeed.items.slice(0, 5).map((l, i) =>
            <Card className="shadow mt-3" key={i}>
                <CardHeader color="white">
                    <h4>{l.title}</h4>
                    <h6>{l.creator} {"\u2219"} {getTimeDifference(new Date(l.pubDate))} {"\u2219"} {this.state.currentFeed.title}</h6>
                </CardHeader>
                <CardBody>
                    <CardText>{l.contentSnippet.replace("&nbsp;", "")}</CardText>
                    <Button color="link" onClick={this.openLink.bind(this, l.link)}>
                        Read More...
                    </Button>
                </CardBody>
            </Card>
        )
    }

    render() {
        if (!this.state.currentFeed) {
            return (
            <div className="center">
                <Spinner size="sm" color="primary" />
                <h1>Loading...</h1>
            </div>
            );
        }

        return (
            <div className="feed-page">
                <div class="d-flex flex-column">
                    {this.formatFeedCards()}
                </div>
            </div>
        );
    }
}

export default NewsFeed;