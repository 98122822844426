import React from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import validator from 'validator';
import { Container, Jumbotron, Button, Form, FormGroup, Label, Input, Alert, FormFeedback, Row, Col } from 'reactstrap';

import JumbotronBackground from "../assets/img/masthead-image.png"

require('dotenv').config()

class ContactPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            captchaAlert: false,
            captchaComplete: false,
            submitted: false,
            validationError: false,
            emailError: false,
            phoneError: false,
            sendError: false
        };

        this.sendEmail = this.sendEmail.bind(this);
        this.validateEmailAddress = this.validateEmailAddress.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.onCaptchaComplete = this.onCaptchaComplete.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    validateEmailAddress(e) {
        e.preventDefault();
        let result = validator.isEmail(e.target.value);
        this.setState({ emailError: !result });
        return result;
    }

    validatePhone(e) {
        e.preventDefault();
        let result = validator.isMobilePhone(e.target.value, "en-US");
        this.setState({ phoneError: !result });
        return result;
    }

    onCaptchaComplete(e) {
        console.log("Captcha value:", e);
        this.setState({ captchaAlert: false, captchaComplete: true });
    }

    validateForm() {
        if (!this.state.captchaComplete) {
            this.setState({ captchaAlert: true });
            return false;
        }

        return true;
    }

    sendEmail(e) {
        e.preventDefault();

        if (!this.validateForm()) {
            console.log("Email not sent...");
            return null;
        }

        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAILJS_USER_ID)
            .then((result) => {
                this.setState({ submitted: true });
            }, (error) => {
                this.setState({ sendError: true });
            });
    }

    render() {
        return (
            <div>
                <Jumbotron className="center" fluid style={{ backgroundImage: `url(${JumbotronBackground})` }}>
                    <div>
                        <h3 className="display-4 text-light text-center" color="light">We would love to hear from you</h3>
                    </div>
                </Jumbotron>
                <Container>
                    <Row>
                        <Col md="6">
                            {!this.state.submitted
                                ?
                                this.state.sendError
                                    ?
                                    <Alert color="danger">
                                        Sorry, there was issue in sending the email, please try again
                                    </Alert>
                                    :
                                    <Form id="contactForm" onSubmit={this.sendEmail}>
                                        <h2>Contact Form</h2>
                                        <h6>Please fill out the form below to send us a message</h6>
                                        <hr />
                                        <FormGroup>
                                            <Label for="nameInput">Name</Label>
                                            <Input type="text" name="sender_name" id="nameInput" required />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="emailInput" >Email</Label>
                                            <Input type="email" name="sender_email" id="emailInput" onChange={this.validateEmailAddress} required invalid={this.state.emailError} />
                                            <FormFeedback>Please enter a valid email address</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="phoneInput">Phone #</Label>
                                            <Input type="phone" name="contact_number" id="phoneInput" onChange={this.validatePhone} required invalid={this.state.phoneError} />
                                            <FormFeedback>Please enter a valid U.S. phone number</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="messageInput">Message</Label>
                                            <Input type="textarea" name="message_text" id="messageInput" required />
                                        </FormGroup>
                                        <FormGroup>
                                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.onCaptchaComplete}></ReCAPTCHA>
                                            {
                                                this.state.captchaAlert ?
                                                    <Alert color="danger">Please complete the captcha!</Alert> :
                                                    <div></div>
                                            }

                                        </FormGroup>
                                        <Button type="submit" id="submitButton">Submit</Button>
                                    </Form>
                                :
                                <Alert color="success">
                                    Email has been sent!
                        </Alert>
                            }
                        </Col>
                        <Col md="6">
                            <h2>Give us a call or SMS</h2>
                            <h6>Reach out to us via the following phone number</h6>
                            <hr />
                            <a className="center" style={{ fontSize: 36 }} href="tel:518-464-1492">518-464-1492</a>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ContactPage;