import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import MainNavbar from './components/navbar-component'
import HomePage from './pages/home-page.component'
import ServicesPageA from './pages/services-page-a.component'
import ServicesPageB from './pages/services-page-b.component'
import ServicesPageC from './pages/services-page-c.component'
import ContactPage from './pages/contact-page.component'

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="App">
          <MainNavbar />
          <Route path="/" exact component={HomePage} />
          <Route exact path="sos.splashtop.com"/>
          <Route path="/services/it-support" exact component={ServicesPageA} />
          <Route path="/services/cloud" exact component={ServicesPageB} />
          <Route path="/services/recovery" exact component={ServicesPageC} />
          <Route path="/contact-us" exact component={ContactPage} />
          <div style={{textAlign: "center"}}>
            <hr/>
            <p>Copyright © {(new Date()).getFullYear()} Tech Valley Networking Services, LLC. All Rights Reserved.</p>
          </div>
          
        </div>
      </Router>
    );
  }
}

export default App;
