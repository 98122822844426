import React from 'react';
import { Container, Jumbotron, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardDeck } from 'reactstrap';
import Accordion from "../components/accordian-component"
import JumbotronBackground from "../assets/img/jumbotron-4-img.png"
import FadingFooter from "../components/fading-footer.component"

import "../assets/css/servicePageB.css"

import img1 from "../assets/img/cloud-img-1.png"
import img2 from "../assets/img/cloud-img-2.png"
import img3 from "../assets/img/cloud-img-3.png"

class ServicesPageB extends React.Component {
    render() {
        return (
            <div>
                <Jumbotron className="center" fluid style={{ backgroundImage: `url(${JumbotronBackground})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                    <div>
                        <h3 className="display-4 text-light text-center" color="light">Cloud Computing</h3>
                        <hr color="white" />
                        <p className="lead text-light text-center" color="light">Your business. On the go.</p>
                    </div>
                </Jumbotron>

                <Container>
                    <Row>
                        <Col sm="7">
                            <h3>Your Business on the Go</h3>
                            <div className="center" >
                                <p>Cloud computing is the future of business. No longer are you limited to your internal network to access your business applications and email. Anywhere you have an internet connection, you have the power of the cloud. Tech Valley Networking’s managed IT services enables our clients to access email, create invoices, and have the same desktop experience from anywhere you have internet access. Don’t limit your business. embrace the scalability and flexibility of cloud computing.</p>
                            </div>
                        </Col>
                        <Col sm="5">
                            <h3>Why Should Your Business Move to the Cloud?</h3>
                            <Accordion>
                                <Accordion.Item>
                                    <Accordion.Header>Accessible Anywhere</Accordion.Header>
                                    <Accordion.Body>Anywhere you have an internet connection, you have access to your business applications. Invoice while on location, send an email without having to wait until you get back to the office, simulate the office experience from the comfort of your home office. No longer are you limited to achieving maximum efficiency only while in the office..</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item>
                                    <Accordion.Header>Cutting Costs</Accordion.Header>
                                    <Accordion.Body>Eliminate the costly expenses associated with purchasing and maintaining your business infrastructure. Save on software and hardware costs as everything is maintained in the cloud. Cut your office costs as you no longer have to maintain a temperature controlled server environment or foot the bill to power a server.</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item>
                                    <Accordion.Header>Maximize Uptime</Accordion.Header>
                                    <Accordion.Body>All of our cloud servers have enterprise replication to ensure your cloud data is secure. Tech Valley Networking’s Cloud Servers are installed on enterprise systems designed for maximum uptime. No longer will you need to worry about your server going down and being unable to work.</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <br />
                    <Row>

                        <Col>
                            <CardDeck>
                                <Card>
                                    <CardImg top width="100%" src={img1} alt="Card image cap" />
                                    <hr />
                                    <CardBody>
                                        <CardTitle className="card-title">Office 365</CardTitle>
                                        <CardText>The Office you know at your fingertips – access your applications and files anywhere. PC, Mac, and mobile devices all allow editing on the go.</CardText>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardImg top width="100%" src={img2} alt="Card image cap" />
                                    <hr />
                                    <CardBody>
                                        <CardTitle className="card-title">Exchange</CardTitle>
                                        <CardText>24/7 access to email from any device. Seamlessly migrate to the cloud to eliminate your reliance on a stationary Exchange server.</CardText>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardImg top width="100%" src={img3} alt="Card image cap" />
                                    <hr />
                                    <CardBody>
                                        <CardTitle className="card-title">Azure</CardTitle>
                                        <CardText>Transition your infrastructure to the cloud. Access your applications anywhere on the international network of Microsoft datacenters.</CardText>
                                    </CardBody>
                                </Card>
                            </CardDeck>
                        </Col>
                    </Row>
                </Container>
                <section className="section mt-5">
                    <FadingFooter/>
                </section>
            </div>
        );
    }
}

export default ServicesPageB;