import React from 'react';
import { FaServer, FaDesktop, FaClone, FaCloudUploadAlt, FaShieldAlt } from 'react-icons/fa';
import { Container, Jumbotron, Row, Col, Media, Card, CardBody, CardText, Button, UncontrolledCollapse, CardHeader, CardGroup } from 'reactstrap';
import JumbotronBackground from "../assets/img/jumbotron-3-img.png"
import FadingFooter from "../components/fading-footer.component"

import "../assets/css/servicePageA.css"

import img1 from "../assets/img/it-support-img-1.jpg"
import img2 from "../assets/img/it-support-img-2.png"
import img3 from "../assets/img/it-support-img-3.png"
import img4 from "../assets/img/it-support-img-4.png"
import img5 from "../assets/img/it-support-img-5.jpg"
import img6 from "../assets/img/it-support-img-6.jpg"
import img7 from "../assets/img/it-support-img-7.png"

class ServicesPageA extends React.Component {
    render() {
        return (
            <div>
                <Jumbotron className="center" fluid style={{ backgroundImage: `url(${JumbotronBackground})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                    <div>
                        <h3 className="display-4 text-light text-center" color="light">Fully Managed IT Services</h3>
                        <hr color="white"/>
                        <p className="lead text-light text-center" color="light">Professional Solutions from Knowledgeable Technicians</p>
                    </div>
                </Jumbotron>

                <section className="section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <img alt="Web Studio" className="img" src={img1} width="600" />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5 ml-auto d-flex align-items-center mt-4 mt-md-0">
                                <div>
                                    <h5>Technology as an Asset</h5>
                                    <p className="margin-top-s">Communication and response times are pillars in Tech Valley Networking’s philosophy to IT Support services. Our professional technicians offer customized IT Consulting plans designed to make technology work for you, rather than the other way around. Tech Valley Networking’s fully managed IT services provide comprehensive solutions for small to medium sized businesses throughout the Capital Region and beyond.</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <img alt="Web Studio" className="img" src={img2} width="600" />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5 ml-auto d-flex align-items-center mt-4 mt-md-0">
                                <div>
                                    <h5>Proactive Management</h5>
                                    <p className="margin-top-s">The break/fix model to IT support is a thing of the past. Tech Valley Networking’s IT Support Services utilize preventative measures to protect your business before a disaster strikes. Full infrastructure maintenance is included in our plans in an effort to minimize your headaches and maximize your profits. Let us take the headaches out your managed IT services so you can focus on what you are good at, running your business.</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <img alt="Web Studio" className="img" src={img3} width="600" />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5 ml-auto d-flex align-items-center mt-4 mt-md-0">
                                <div>
                                    <h5>Full Infrastructure Management</h5>
                                    <p className="margin-top-s">Tech Valley Networking’s fully managed IT services includes full infrastructure monitoring for our clients. To us, fully managed means we take the stress and confusion out of your business’ technological needs. Our full infrastructure monitoring includes servers, workstations, firewalls, printers, etc. We take care of everything for our clients, leaving them free to utilize technology as an asset to your organization rather than a burden.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section mt-5 align-items-center">
                    <Container>
                        <CardGroup>

                            <Card style={{ minWidth: '240px' }}>
                                <CardHeader>
                                    <Button block color="primary" id="toggler0" style={{ marginBottom: '1rem' }}>
                                        APPLICATIONS
                                </Button>
                                </CardHeader>
                                <UncontrolledCollapse toggler="#toggler0">
                                    <CardBody>
                                        <div className="center">
                                            <FaClone size={128} color="blue" />
                                        </div>
                                        <br />
                                        <CardText>Every organization runs different software and applications. Our knowledge of on-premise and cloud based application management can help you deploy and maintain your critical applications.</CardText>
                                    </CardBody>
                                </UncontrolledCollapse>

                            </Card>

                            <Card style={{ minWidth: '240px' }}>
                                <CardHeader>
                                    <Button block color="primary" id="toggler1" style={{ marginBottom: '1rem' }}>
                                        SERVERS
                                </Button>
                                </CardHeader>
                                <UncontrolledCollapse toggler="#toggler1">                                <CardBody>

                                    <div className="center">
                                        <FaServer size={128} color="blue" />
                                    </div>
                                    <br />
                                    <CardText>Uptime is everything.  We can monitor and maintain your servers while your office stays running smoothly.  We allow peace of mind with the ability to monitor your hardware 24/7.</CardText>
                                </CardBody>
                                </UncontrolledCollapse>
                            </Card>

                            <Card style={{ minWidth: '240px' }}>
                                <CardHeader>
                                    <Button block color="primary" id="toggler2" style={{ marginBottom: '1rem' }}>
                                        WORKSTATIONS
                                </Button>
                                </CardHeader>
                                <UncontrolledCollapse toggler="#toggler2">
                                    <CardBody>
                                        <div className="center">
                                            <FaDesktop size={128} color="blue" />
                                        </div>
                                        <br />
                                        <CardText>How efficient are your workstations? Have you had any maintenance on them recently? We can assist with Virus Protection and scheduled maintenance to keep your local hardware running smoothly and securely.</CardText>
                                    </CardBody>
                                </UncontrolledCollapse>
                            </Card>

                            <Card style={{ minWidth: '240px' }}>
                                <CardHeader>
                                    <Button block color="primary" id="toggler3" style={{ marginBottom: '1rem' }}>
                                        SECURITY
                                </Button>
                                </CardHeader>

                                <UncontrolledCollapse toggler="#toggler3">
                                    <CardBody>
                                        <div className="center">
                                            <FaShieldAlt size={128} color="blue" />
                                        </div>
                                        <br />
                                        <CardText>Securing your Digital Resources is more important than ever. Are you using outdated encryption? When was the last time you audited your network equipment? Let us update and maintain your security. Remove risk from your business.</CardText>
                                    </CardBody>
                                </UncontrolledCollapse>

                            </Card>

                            <Card style={{ minWidth: '240px' }}>
                                <CardHeader>
                                    <Button block color="primary" id="toggler4" style={{ marginBottom: '1rem' }}>
                                        BACKUP
                                </Button>
                                </CardHeader>

                                <UncontrolledCollapse toggler="#toggler4">
                                    <CardBody>
                                        <div className="center">
                                            <FaCloudUploadAlt size={128} color="blue" />
                                        </div>
                                        <br />
                                        <CardText>Auditing and monitoring your data backups is a crucial part of your business infrastructure.  Minimize risk and downtime by utilizing an enterprise level backup service.  Loss of data is not a matter of “if”, but “when”.  Hybrid backup solutions that meet Compliance Regulations, including data verification and Secure Encrypted Offsite Cloud Storage.</CardText>
                                    </CardBody>
                                </UncontrolledCollapse>

                            </Card>

                        </CardGroup>
                    </Container>
                </section>

                <section className="section mt-5">
                    <Container>
                        <Row>
                            <Col sm="3" className="text-center">
                                <Media className="img-fluid zoom" src={img4}></Media>
                            </Col>
                            <Col sm="6">
                                Headquartered in Albany, NY, Tech Valley Networking has been providing IT Support Services in the Capital Region for the last 15 Years. We have had a great experience with providing IT Services to those in the Capital Region who are interested in utilizing technology to enhance their business in various ways.  We offer a vast array of IT Services to all of our clients and have helped many organizations save monthly costs through bundling managed services in one place. A no cost, no obligation network review is available for all organizations in our local service areas.   Contact us today to schedule a meeting.
                            </Col>
                            <Col sm="3" className="text-center">
                                <Media className="img-fluid zoom" src={img5}></Media>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="5" className="text-center">
                                <Media className="img-fluid zoom" src={img6}></Media>
                            </Col>
                            <Col sm="7" className="text-center">
                                <Media className="img-fluid zoom" src={img7}></Media>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section mt-5">
                    <FadingFooter/>                   
                </section>
            </div>
        );
    }
}

export default ServicesPageA;