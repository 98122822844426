import React from 'react';
import { Jumbotron, Row, Col } from 'reactstrap';
import JumbotronBackground from "../assets/img/jumbotron-2-img.jpg"
import NewsFeed from '../components/newsfeed-component';

class HomePage extends React.Component {
    render() {
        return (
            <div>
                <Jumbotron className="center" style={{ backgroundImage: `url(${JumbotronBackground})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                    <div>
                        <h3 className="display-4 text-light text-center" color="light">MANAGED IT SERVICES</h3>
                        <hr color="white"/>
                        <p className="lead text-light text-center" color="light">Constatnly challenging ourselves to provide the best value to our clients</p>
                    </div>
                </Jumbotron>              
                <div class="container-fluid" style={{backgroundColor: "#9c9c9c"}}>
                    <br/>
                    <h1 className="center text-light">Tech News</h1>
                    <Row>
                        <Col xl="4">
                            <NewsFeed src="https://feeds.feedburner.com/TechCrunch/" />
                        </Col>
                        <Col xl="4">
                            <NewsFeed src="https://www.cnet.com/rss/news" />
                        </Col>
                        <Col xl="4">
                            <NewsFeed src="https://www.technologyreview.com/feed/" />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default HomePage;
