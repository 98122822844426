import BrandLogo from '../assets/img/logo.svg'
import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media
} from 'reactstrap';


const MainNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <div className="center">
        <NavbarBrand href="/">
          <Media object src={BrandLogo} alt="Generic placeholder image" style={{ maxHeight: 256 }} />
        </NavbarBrand>
      </div>
    
      <Navbar color="light" light expand="md">
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="navbar-nav nav-fill w-100">
            <NavItem style={{border: "solid #eee 1px"}}>
              <NavLink href="/" color="success">Home</NavLink>
            </NavItem>
            <NavItem style={{border: "solid #eee 1px"}}>
              <a className="nav-link" href="https://sos.splashtop.com" target="_blank" rel="noopener noreferrer">Remote Support</a>
            </NavItem>
            <UncontrolledDropdown nav inNavbar style={{border: "solid #eee 1px"}}>
              <DropdownToggle nav caret>
                Services
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="/services/it-support">
                  Managed IT
                </DropdownItem>
                <DropdownItem href="/services/cloud">
                  Cloud Computing
                </DropdownItem>
                <DropdownItem href="/services/recovery">
                  Disaster Recovery
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem style={{border: "solid #eee 1px"}}>
              <NavLink href="/contact-us">Contact Us</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

    </div>
  );
}

export default MainNavbar;