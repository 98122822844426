import React from 'react';
import {Button} from 'reactstrap'

import '../assets/css/fadingFooter.css'

class FadingFooter extends React.Component {
    render() {
        return (
            <div className="fade-container">
                <div className="parallax"></div>
                <div class="middle">
                    <h5 className="text-light text-center">TECHNOLOGY DONE RIGHT</h5>
                    <p className="lead text-light text-center">We work along side our clients to provide managed IT solutions that coincide with their business goals.</p>
                    <Button color="danger" href="/contact-us">
                        Contact Us
                    </Button>
                </div>
            </div>
        );
    }
}

export default FadingFooter;